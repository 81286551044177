import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Open the trail editor`}</h4>
    <p>{`Open `}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`trailguide.net`}</a>{` on your desktop computer and `}<Link to="/features/login" mdxType="Link">{`log in`}</Link>{`.`}</p>
    <br />
    <p>{`Click on the "Add" button next to your profile picture.`}</p>
    <Image src="features/addtrailbutton.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <h4>{`Import a GPX track`}</h4>
    <p>{`Drag and drop your GPX file into the map section of the map editor. When it
is uploaded, you see the track and height profile in the trail editor.`}</p>
    <Image src="features/addtrailgpxtrack.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <p>{`GPX files (`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/GPS_Exchange_Format"
      }}>{`GPS Exchange Format`}</a>{`) is
a common standard to save and exchange locations and tracks. Most apps and
devices use this standard.`}</p>
    <br />
    <p>{`There are many apps for recording and exporting GPX tracks. `}<a parentName="p" {...{
        "href": "https://strava.com"
      }}>{`Strava`}</a>{` is used by
many. On their website you can export the recorded GPX track and save it to
your computer.`}</p>
    <br />
    <br />
    <h4>{`Edit the track`}</h4>
    <p>{`Use the slider below the map to cut the start and end point of the track.`}</p>
    <br />
    <p>{`If the track is more appropriately mapped in reverse order, you can do so
by clicking the "Reverse" icon to the right of the slider.`}</p>
    <br />
    <p>{`Edit the track appropriately to the selected activity type.`}</p>
    <br />
    <p>{`When you upload to the "MTB singletrack" category cut away the parts on paved
or gravel roads.`}</p>
    <Image src="features/cuttrail.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <h4>{`Select an activity type`}</h4>
    <p>{`Click on the "Activity type" dropdown and select the one you want.`}</p>
    <br />
    <p>{`Make sure to select the right type to keep the quality of content high and
the app useful for all users!`}</p>
    <br />
    <Link to="/pro/activitytype" mdxType="Link">See more about the different activity types here</Link>.
    <Image src="features/selectcategory.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <h4>{`Select a difficulty`}</h4>
    <p>{`Click on the "Difficulty level" dropdown and select the one that fits best.`}</p>
    <br />
    <p>{`This is the technical difficulty of the activity! You should not not consider
any physical demands here. This is visible from the length, elevation,
technical difficulty, and additional information in the description!`}</p>
    <br />
    <Link to="/pro/description" mdxType="Link">
  Read more about the difficulty rating systems.
    </Link>
    <Image src="features/selectdifficulty.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <h4>{`Select the visibility`}</h4>
    <p>{`Click on the "Visibility" dropdown and choose an option.`}</p>
    <br />
    <p><strong parentName="p">{`Public`}</strong>{` shows the track to every user on the public map and in the list. It
appears in the search.`}</p>
    <br />
    <p><strong parentName="p">{`Hidden`}</strong>{` hides the track from the public map and in the list. It does not
appear in the search, but you can share the URL of the track. Everyone who has
the URL can access the activity page and show the track on the main map. You
find hidden tracks in `}<Link to="/features/userpage" mdxType="Link">{`My page`}</Link>{`.`}</p>
    <br />
    <p><strong parentName="p">{`Secret`}</strong>{` hides the track from everyone but you. You find hidden tracks
in `}<Link to="/features/userpage" mdxType="Link">{`My page`}</Link>{`.`}</p>
    <Image src="features/selectvisibility.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <h4>{`Add a description`}</h4>
    <p>{`The location of the activity is generated automatically from the information
of the GPX track file.`}</p>
    <br />
    <p>{`Give the activity a characteristic and significant name. Keep it short and
succinct.`}</p>
    <br />
    <p>{`We recommend starting with a description of the character of the activity or
the experience in one comprehensive sentence. For example `}<em parentName="p">{`"Easy natural trail
with a stunning view into the Great Valley."`}</em></p>
    <br />
    <p>{`Then a more detailed description, but still compact and limited to important
information. Describe the charactar and what makes this track unique.`}</p>
    <br />
    <p>{`Do not describe the routing instructions (turn left here, 100m straight,
then right, etc...). This is what the GPS track is for.`}</p>
    <Image src="features/traildescription.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <h4>{`Advanced options`}</h4>
    <p>{`Click to select attribute labels of the trail.`}</p>
    <br />
    <p>{`Open more options by clicking on the "Advanced" dropdown.`}</p>
    <br />
    <p>{`Enter keywords separated by comma. They are not visible in the activity page,
but are searchable.`}</p>
    <br />
    <p>{`Add a video by adding URL to a Youtube video.`}</p>
    <br />
    <p>{`Select possible ways of access or how well the trail can take rain.`}</p>
    <br />
    <p>{`Click on "Save" when you are finished.`}</p>
    <Image src="features/addtrailadvanced.png" className="w-3/4 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/contactuser" mdxType="Link">
  ← Contact user
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      